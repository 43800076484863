.configurationComponent{
    background-color: black;
    color: white;
    position: absolute;
    left: 79vw;
    width: 21vw;
    text-align: left;
    float: right;
}
input{
    background-color: black;
    color: white;
    border-color: gray;
    margin: 5px 2px 2px 2px;
}
.subHeader{
    font-weight: bold;
}

.option{
    margin-left: 4%;
}
.optionText{
    width: 70%;
}
.smallFields{
    margin-left: 10%;
}
.optionColor{
    width: 4em;
}
.optionColorColoredSquare{
    padding: 0em 1.5em;
    margin: 0em 0.2em;
    border: 1px solid gray;
}
.optionWeight{
    width: 3em;
}
.addOptionButton{
    margin-left: 5%;
}
.subOption{
    margin-left: 5%;
}
.subOptionSpaced{
    margin: 0em 1em;
}

.openConfigButton {
    float: right;
    background-color: gray;
    color: white;
    position: absolute;
    width: 15vw;
    text-align: center;

    margin-right: 1vw;

}
input[type='radio']:checked:after {
    width: 10px;
    height: 10px;
    border: 1px solid #f8b195;
    border-radius: 50%;
    top: -2px;
    left: -1px;
    position: relative;
    background: #2f2 url("../../../images/wheelchan/homyksDerp.png");
    background-size: 100%;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.inlinePopUp{
    border: 1px solid white;
    position: absolute;
    text-align: center;
    background: black;
    padding: 1em;
    margin: 1em;
    z-index: 99;
}
.info{
    margin-left: 1em;
    color: #22ff22;
}
.error{
    color: red;
}

.configSelect{
    margin: 0em 1em;
}
.plainButton{
    margin: 0.2em 0em;
}