.openConfigButton {
    background-color: gray;
    color: white;
    position: absolute;
    width: 15vw;
    text-align: center;
    left: 84vw;
    float: right;
    margin-right: 1vw;
}

.popUpComponent {
    position: fixed;
    max-height: calc(100% - 100px);
    min-width: 60vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0,0,0, 0.8);
    color: white;
    border: 1px solid #fff;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0,0,0, 0.5);

    opacity: 1;
    visibility: visible;
}
.popUpWinner{
    font-size: 5em;
}
.exportText{
    border: 1px solid #fff;
    max-width: 40vw;
    max-height: 90vh;
}
.importPopUpTextArea{
    background: rgba(0,0,0, 0.8);
    color: white;
}
