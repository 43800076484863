.main {
    background-color: #000000;
    margin: 0px;
    height: 100vh;
    width: 100vw;
    color: white;
}

.popup{
    max-width: 30vw;
    margin: 0 auto;
    background-color: #000000;
    border: 0.4vh solid #ffffff;
    min-width: 300px;
}
.Text{
    font-family: Copperplate,Copperplate Gothic Light,fantasy;
}
.singlePanel{
    text-align: center;
    float: left;
    max-width: 12vw;
    margin: 0.1vw;
    min-width: 12vmax;
}
.singlePanelImage{
    width: 12vmax;
}
.singlePanelText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12vmax;
}
.singlePanelLiveSign{
    z-index: 1;
    position: absolute;
    color: white;
    background: red;
}
.wheelName{
    background-color: #85a9c9;
    color: white;
    padding: 0 1em;
    margin: 0.5em;
    width: 30vw;
    text-align: left;
}

.sectionHeader{
    text-align: left;
    font-size: 2em;
    width: 100vw;
    padding: 0.2em 0.5em;
    background-color: #203049;
    color: white;
}
.wheelButtons{
    width: 30em;
    text-align: left;
}
.halfcenter{
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
    max-height: 100vh;
}
.menuSection{
    margin: 1em;
}
.button{
    background-color: #112c53;
    color: #d9effa;
    padding: 2px;
    margin: 2px;
    border: 2px solid #061224;
    font-size: 1em;
}
.button:hover{
    font-size: 1em;
    background-color: #1e539f;
}
.disabledButton, .disabledButton:hover{
    background-color: #666666;
}
.wheelchan input[type="text"], textarea {
    background-color: #666;
    color: #ffffff;
}
.wheelchan input[type="text"]::placeholder, textarea::placeholder {
    background-color: #666;
    color: #ddd;
}
.selectPictureButton{
    margin: 1vh auto;
}
.activateButcherButton{
    max-width: 15vw;
    margin: 0 auto;
    font-size: 3em;
    background: #112c53;
    min-width: 90%;
}
.activateButcherButton:hover{
    max-width: 15vw;
    margin: 0 auto;
    background: #4a91f7;
}
.deactivateButcherButton{
    max-width: 10vw;
    margin: 1vh auto;
    font-size: 1em;
    background: #0b1b33;
    min-width: 60%;
}
.deactivateButcherButton:hover{
    background: #4a91f7
}
.wheelchan img{
    max-width: 100%;
}
