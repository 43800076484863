

.skullsViewContainer{
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    color: black;
}


.skull {
    float: left;
    width: 180px;
}
.skullTitle{
    margin: 0;
    padding: 0;
    text-align: center;
    color: #1e539f;
    position: relative;
    top: -18px;
}